@import "../../assets/styles/utils";

.btm-section{
  position: relative;
  @include res(height,100vh,(md:60vw,xs:120vw));
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .bg-img{
    width: 100%;
    height: 120%;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: -10%;
    left: 0;
    z-index: 0;
  }
  .text{
    width: 100%;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    z-index: 3;
    text-align: center;
    @include res(bottom,.5rem,(xs:.3rem));
    @include res(padding-left,1.3rem,(xs:.3rem));
    @include res(padding-right,1.3rem,(xs:.3rem));
    h2{
      line-height: 1.4;
      @include res(font-size,1.6rem,(xs:.9rem));
    }
    .btn{
      @include res(margin-top,.35rem,(xs:.2rem));
      @include res(margin-bottom,.35rem,(xs:.2rem));
      a{
        display: inline-block;
        border-style: solid;
        border-color: #e6d7b8;
        border-radius: 50px;
        @include res(font-weight,lighter,(md:normal));
        transition: all .3s;
        @include res(color,#e6d7b8,(md:#333));
        @include res(background-color,transparent,(md:#e6d7b8));
        @include res(font-size,.18rem);
        @include res(border-width,.01rem,(md:1px));
        @include res(padding-left,.5rem);
        @include res(padding-right,.5rem);
        @include res(padding-top,.06rem);
        @include res(padding-bottom,.06rem);
        &:hover{
          background-color: #e6d7b8;
          color: #333;
        }
      }
    }
    .icon{
      img{
        height: auto;
        @include res(width,.68rem);
      }
    }
  }
  .text2{
    position: absolute;
    z-index: 2;
    border-radius: 500px;
    overflow: hidden;
    background-color: rgba(41,86,89,.9);
    box-sizing: border-box;
    text-align: center;
    @include res(width,3.4rem);
    @include res(top,8%);
    @include res(left,20%);
    @include res(padding-left,.55rem,(xs:.3rem));
    @include res(padding-right,.55rem,(xs:.3rem));
    @include res(padding-top,1.25rem,(xs:.8rem));
    @include res(padding-bottom,1.6rem,(xs:1rem));
    &::after{
      content: '';
      display: block;
      pointer-events: none;
      border-radius: 500px;
      border: solid #95a69f;
      position: absolute;
      @include res(top,.1rem);
      @include res(left,.1rem);
      @include res(border-width,.02rem);
      @include res(width,calc(100% - .2rem));
      @include res(height,calc(100% - .2rem));
    }
    .logo{
      img{
        height: auto;
        @include res(width,70%);
      }
    }
    p{
      color: #f1ebdb;
      line-height: 1.5;
      @include res(margin-top,.4rem);
    }
  }
}