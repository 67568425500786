.pages, #page ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

@media (max-width: 1600px) {
  .pages, #page ul {
    margin-top: 44px;
  }
}

@media (max-width: 1366px) {
  .pages, #page ul {
    margin-top: 38px;
  }
}

@media (max-width: 1024px) {
  .pages, #page ul {
    margin-top: 32px;
  }
}

@media (max-width: 991px) {
  .pages, #page ul {
    margin-top: 26px;
  }
}

@media (max-width: 767px) {
  .pages, #page ul {
    margin-top: 20px;
  }
}

.pages a, .pages li, #page ul a, #page ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f2f2f3;
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
  transition: all .4s;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 18px;
  width: 50px;
  height: 50px;
}

@media (max-width: 1600px) {
  .pages a, .pages li, #page ul a, #page ul li {
    margin-left: 9px;
  }
}

@media (max-width: 1366px) {
  .pages a, .pages li, #page ul a, #page ul li {
    margin-left: 8px;
  }
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    margin-left: 7px;
  }
}

@media (max-width: 991px) {
  .pages a, .pages li, #page ul a, #page ul li {
    margin-left: 6px;
  }
}

@media (max-width: 767px) {
  .pages a, .pages li, #page ul a, #page ul li {
    margin-left: 5px;
  }
}

@media (max-width: 1600px) {
  .pages a, .pages li, #page ul a, #page ul li {
    margin-right: 9px;
  }
}

@media (max-width: 1366px) {
  .pages a, .pages li, #page ul a, #page ul li {
    margin-right: 8px;
  }
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    margin-right: 7px;
  }
}

@media (max-width: 991px) {
  .pages a, .pages li, #page ul a, #page ul li {
    margin-right: 6px;
  }
}

@media (max-width: 767px) {
  .pages a, .pages li, #page ul a, #page ul li {
    margin-right: 5px;
  }
}

@media (max-width: 1600px) {
  .pages a, .pages li, #page ul a, #page ul li {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .pages a, .pages li, #page ul a, #page ul li {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .pages a, .pages li, #page ul a, #page ul li {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .pages a, .pages li, #page ul a, #page ul li {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .pages a, .pages li, #page ul a, #page ul li {
    width: 48px;
  }
}

@media (max-width: 1366px) {
  .pages a, .pages li, #page ul a, #page ul li {
    width: 46px;
  }
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    width: 44px;
  }
}

@media (max-width: 991px) {
  .pages a, .pages li, #page ul a, #page ul li {
    width: 42px;
  }
}

@media (max-width: 767px) {
  .pages a, .pages li, #page ul a, #page ul li {
    width: 40px;
  }
}

@media (max-width: 1600px) {
  .pages a, .pages li, #page ul a, #page ul li {
    height: 48px;
  }
}

@media (max-width: 1366px) {
  .pages a, .pages li, #page ul a, #page ul li {
    height: 46px;
  }
}

@media (max-width: 1024px) {
  .pages a, .pages li, #page ul a, #page ul li {
    height: 44px;
  }
}

@media (max-width: 991px) {
  .pages a, .pages li, #page ul a, #page ul li {
    height: 42px;
  }
}

@media (max-width: 767px) {
  .pages a, .pages li, #page ul a, #page ul li {
    height: 40px;
  }
}

.pages a:hover, .pages li:hover, #page ul a:hover, #page ul li:hover {
  background-color: #ebebeb;
  color: #295659;
}

.pages a.active, .pages a.xl-active, .pages li.active, .pages li.xl-active, #page ul a.active, #page ul a.xl-active, #page ul li.active, #page ul li.xl-active {
  background-color: #295659;
  color: #fff;
}

.pages a.xl-prevPage, .pages a.xl-nextPage, .pages li.xl-prevPage, .pages li.xl-nextPage, #page ul a.xl-prevPage, #page ul a.xl-nextPage, #page ul li.xl-prevPage, #page ul li.xl-nextPage {
  display: none;
}

.pages .page_jump_text, #page ul .page_jump_text {
  margin-left: 10px;
  margin-right: 10px;
}

.pages .page_jump_input, #page ul .page_jump_input {
  width: 60px;
  height: 30px;
}

@media (max-width: 1600px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    width: 60px;
  }
}

@media (max-width: 1366px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    width: 60px;
  }
}

@media (max-width: 1024px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    width: 60px;
  }
}

@media (max-width: 991px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    width: 60px;
  }
}

@media (max-width: 767px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    width: 60px;
  }
}

@media (max-width: 1600px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    height: 30px;
  }
}

@media (max-width: 1366px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    height: 30px;
  }
}

@media (max-width: 1024px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    height: 30px;
  }
}

@media (max-width: 991px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    height: 30px;
  }
}

@media (max-width: 767px) {
  .pages .page_jump_input, #page ul .page_jump_input {
    height: 30px;
  }
}

.pages .page_jump_btn, #page ul .page_jump_btn {
  background: none;
  border: none;
  cursor: pointer;
  transition: all;
}

.pages .page_jump_btn:hover, #page ul .page_jump_btn:hover {
  color: #295659;
}
