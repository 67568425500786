@import "../../assets/styles/utils";

.page-header{
  background-color: #fff;
  border-bottom: solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  @include res(border-bottom-width,.01rem,(md:1px));
  @include res(height,$header-height-base,$header-height-ratio);
  .logo{
    img{
      display: block;
      width: auto;
      @include res(height,.6rem,(md:.7rem,sm:.8rem,xs:.6rem));
    }
  }
}