@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/dist/css/swiper.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "~locomotive-scroll/dist/locomotive-scroll.css";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'edw';
  src: url('../fonts/AlexBrush.woff') format('woff'),
    url('../fonts/AlexBrush.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


*{
  outline:none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
html{
  font-size: 62.5%;
}
body {
  font-family: "Microsoft YaHei","PingFang SC";
  line-height: 2;
  position: relative;
  color: #4a4647;
  background-color: #f5eedd;
  @include res(font-size, 12px);
}

.comp-root{
  overflow-x: hidden;
  @include res(font-size,.2rem,(md:.3rem,sm:.36rem,xs:.3rem));
}


//主体内容部分宽度
.container {
  @include res(padding-left, 11.2%, 5 / 11.2);
  @include res(padding-right, 11.2%, 5 / 11.2);
}


span.block {
  will-change: transform;
}

.block {
  display: block;
}
.no_overflow {
  overflow: hidden;
}
.no_height {
  transform: scale(1,0);
  pointer-events: none;
  transform-origin: top;
}
.no_width {
  transform: scale(0,1);
  pointer-events: none;
}
.relative{
  position: relative;
}
.inline_block {
  display: inline-block;
  will-change: transform;
}

.top_low {
  transform: translateY(20px);
  opacity: 0;
}
.top_single {
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  opacity: 0
}
.top_double {
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  opacity: 0
}
.top_hidden {
  transform: translateY(101%);
}
.top_translated {
  transform: translateY(15vw);
}

.top_double.is-inview,
.is-inview .top_double,
.top_single.is-inview,
.is-inview .top_single,
.top_hidden.is-inview,
.top_low.is-inview,
.top_translated.is-inview,
.is-inview .top_hidden,
.is-inview .top_low,
.is-inview .top_translated,
.no_width.is-inview,
.is-inview .no_width,
.no_height.is-inview,
.is-inview .no_height {
  -webkit-transform: none;
  transform: none;
  pointer-events: all;
  opacity: 1;
}

.t_container {
  transform: translateX(0);
  height: 100%;
}
.t_container:nth-child(2) {
  transition-delay: 100ms;
}
.mask_left {
  transform: translateX(50%);
  display: block;
}
.mask_left.full {
  transform: translateX(-100%);
}
.mask_left > .t_container {
  transform: translateX(-100%);
  display: block;
}
.mask_left.full > .t_container {
  transform: translateX(100%);
}
.t_container img {
  transform: scale(1.05);
}
.mask_right {
  transform: translateX(-50%);
}
.mask_right > .t_container {
  transform: translateX(100%);
}
.mask_right.full {
  transform: translateX(100%);
}
.mask_right.full > .t_container {
  transform: translateX(-100%);
}
.mask_top {
  transform: translateY(-50%);
}
.mask_top > .t_container {
  transform: translateY(50%);
}
.mask_top.full {
  transform: translateY(-100%);
}
.mask_top > .t_container {
  transform: translateY(100%);
}
.mask_bottom {
  transform: translateY(50%);
}
.mask_bottom > .t_container {
  transform: translateY(-50%);
}
.mask_bottom.full {
  transform: translateY(100%);
}
.mask_bottom.full > .t_container {
  transform: translateY(-100%);
}
.mobileOnly {
  display: none;
}
.is-inview > .mask_bottom,
.is-inview > .mask_left,
.is-inview > .mask_left .t_container,
.is-inview > .mask_left .t_container img,
.is-inview > .mask_right,
.is-inview > .mask_right .t_container,
.is-inview > .mask_right .t_container img,
.is-inview > .mask_top,
.is-inview > .mask_top .t_container,
.is-inview > .mask_top .t_container img,
.is-inview > .mask_bottom,
.is-inview > .mask_bottom .t_container,
.is-inview > .mask_bottom .t_container img {
  transform: none;
}
.transition_all {
  transition-property: all !important;
}

.tran_5000 {
  -webkit-transition: -webkit-transform 5e3ms cubic-bezier(0.19, 1, 0.22, 1),
    opacity 5e3ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 5e3ms cubic-bezier(0.19, 1, 0.22, 1),
    opacity 5e3ms cubic-bezier(0.19, 1, 0.22, 1);
}
.tran_3000 {
  transition: transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1),
    opacity 3e3ms cubic-bezier(0.19, 1, 0.22, 1);
}
.tran_2000 {
  -webkit-transition: -webkit-transform 2e3ms cubic-bezier(0.19, 1, 0.22, 1),
    opacity 2e3ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 2e3ms cubic-bezier(0.19, 1, 0.22, 1),
    opacity 2e3ms cubic-bezier(0.19, 1, 0.22, 1);
}
.tran_1500 {
  -webkit-transition: -webkit-transform 1500ms cubic-bezier(0.19, 1, 0.22, 1),
    opacity 1e3ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 1500ms cubic-bezier(0.19, 1, 0.22, 1),
    opacity 1e3ms cubic-bezier(0.19, 1, 0.22, 1);
}
.tran_1000 {
  -webkit-transition: -webkit-transform 1e3ms cubic-bezier(0.19, 1, 0.22, 1),
    opacity 1e3ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 1e3ms cubic-bezier(0.19, 1, 0.22, 1),
    opacity 1e3ms cubic-bezier(0.19, 1, 0.22, 1);
}
.tran_800 {
  -webkit-transition: -webkit-transform 800ms cubic-bezier(0.19, 1, 0.22, 1),
    opacity 800ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 800ms cubic-bezier(0.19, 1, 0.22, 1),
    opacity 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
.tran_400 {
  -webkit-transition: -webkit-transform 400ms cubic-bezier(0.19, 1, 0.22, 1),
    opacity 400ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 400ms cubic-bezier(0.19, 1, 0.22, 1),
    opacity 400ms cubic-bezier(0.19, 1, 0.22, 1);
}

.is-inview .d50,
.is-inview.d50 {
  transition-delay: 50ms;
}
.is-inview .d100,
.is-inview.d100 {
  transition-delay: 100ms;
}
.is-inview .d150,
.is-inview.d150 {
  transition-delay: 150ms;
}
.is-inview .d200,
.is-inview.d200 {
  transition-delay: 200ms;
}
.is-inview .d250,
.is-inview.d250 {
  transition-delay: 250ms;
}
.is-inview .d300,
.is-inview.d300 {
  transition-delay: 300ms;
}
.is-inview .d350,
.is-inview.d350 {
  transition-delay: 350ms;
}
.is-inview .d400,
.is-inview.d400 {
  transition-delay: 400ms;
}
.is-inview .d450,
.is-inview.d450 {
  transition-delay: 450ms;
}
.is-inview .d500,
.is-inview.d500 {
  transition-delay: 500ms;
}
.is-inview .d550,
.is-inview.d550 {
  transition-delay: 550ms;
}
.is-inview .d600,
.is-inview.d600 {
  transition-delay: 600ms;
}
.is-inview .d650,
.is-inview.d650 {
  transition-delay: 650ms;
}
.is-inview .d700,
.is-inview.d700 {
  transition-delay: 700ms;
}
.is-inview .d750,
.is-inview.d750 {
  transition-delay: 750ms;
}
.is-inview .d800,
.is-inview.d800 {
  transition-delay: 800ms;
}
.is-inview .d850,
.is-inview.d850 {
  transition-delay: 850ms;
}
.is-inview .d900,
.is-inview.d900 {
  transition-delay: 900ms;
}
.is-inview .d950,
.is-inview.d950 {
  transition-delay: 950ms;
}
.is-inview .d1000,
.is-inview.d1000 {
  transition-delay: 1e3ms;
}
.is-inview .d1050,
.is-inview.d1050 {
  transition-delay: 1050ms;
}
.is-inview .d1100,
.is-inview.d1100 {
  transition-delay: 1100ms;
}
.is-inview .d1150,
.is-inview.d1150 {
  transition-delay: 1150ms;
}
.is-inview .d1200,
.is-inview.d1200 {
  transition-delay: 1200ms;
}
.is-inview .d1250,
.is-inview.d1250 {
  transition-delay: 1250ms;
}
.is-inview .d1300,
.is-inview.d1300 {
  transition-delay: 1300ms;
}
.is-inview .d135,
.is-inview.d1350 {
  transition-delay: 1350ms;
}
.is-inview .d1400,
.is-inview.d1400 {
  transition-delay: 1400ms;
}


.common-link{
  border-radius: 50%;
  background-color: #f5eedd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: #fff;
  @include res(width,.4rem,(md:30px));
  @include res(height,.4rem,(md:30px));
  .iconfont{
    display: inline-block;
    @include res(font-size,.22rem,(md:18px));
  }
}

.common-tag{
  white-space: nowrap;
  .tag{
    display: inline-block;
    background: $color-main;
    color: #fff;
    position: relative;
    overflow: hidden;
    line-height: 1.2;
    text-align: center;
    @include res(padding,.6rem .7rem,(xs:.3rem .4rem));
    @include res(font-size,.44rem,(xs:.36rem));
    &::after{
      content: '';
      display: block;
      position: absolute;
      border: solid #f5eedd;
      pointer-events: none;
      @include res(border-width, .01rem,(md:1px));
      @include res(left,.07rem);
      @include res(top,.07rem);
      @include res(width,calc(100% - .14rem));
      @include res(height,calc(100% - .14rem));
    }
    .desc{
      @include res(font-weight,lighter,(md:normal));
      @include res(margin-top,.15rem);
      @include res(font-size,.18rem,(md:.28rem,xs:.26rem));
    }
    .jiao{
      b{
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        pointer-events: none;
        background: url(../../assets/images/tag_bg.jpg) no-repeat center;
        background-size: 100% 100%;
        @include res(width,.19rem);
        @include res(height,.19rem);
        &:nth-child(1){
          top: 0;
          left: 0;
          // transform: rotate(-45deg);
        }
        &:nth-child(2){
          top: 0;
          right: 0;
          transform: rotate(90deg);
        }
        &:nth-child(3){
          bottom: 0;
          left: 0;
          transform: rotate(-90deg);
        }
        &:nth-child(4){
          bottom: 0;
          right: 0;
          transform: rotate(180deg);
        }
      }
    }
  }
  &.has-desc{
    .tag{
      @include res(padding,.4rem 1.6rem,(xs:.3rem 1rem));
    }
  }
}

.flourish{
  font-family: 'edw';
  line-height: 1;
  color: #efe3c6;
  white-space: nowrap;
  // @include res(text-shadow,2px 1px 2px #ddbb97,(sm:1px 1px 1px #ddbb97));
  &.black{
    color: #4a4647;
    // text-shadow: 0px 0px 0px #4a4647;
  }
  &.white{
    color: #ffffff;
    // @include res(text-shadow,2px 1px 2px #f0dccf,(sm:1px 1px 1px #f0dccf));
  }
  &.no_shadow{
    text-shadow: none;
  }
}

.heng-line{
  position: relative;
  margin: 0 auto;
  transform-origin: center;
  transform: scale(0,1);
  opacity: 0;
  @include res(width,100%);
  @include res(height,.14rem);
  &.left-t{
    transform-origin: left;
    transform: scale(.2,1);
  }
  &.white{
    &::before,&::after{
      background-color: #fff;
    }
  }
  &.reverse{
    &::before{
      bottom: 0;
      top: auto;
    }
    &::after{
      top: 0;
      bottom: auto;
    }
  }
  &::before,&::after{
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    background-color: #f5eedd;
  }
  &::before{
    top: 0;
    @include res(height,.04rem);
  }
  &::after{
    bottom: 0;
    @include res(height,.02rem);
  }
  &.is-inview{
    opacity: 1;
    transform: scale(1,1);
  }
}
.is-inview .heng-line{
  opacity: 1;
  transform: scale(1,1);
}

.shu-bg-c{
  position: relative;
  padding: 1px 0;
  .common-shu-line{
    position: absolute;
    top: 0;
    z-index: -1;
    background: url(../../assets/images/line_bg.png) repeat-y;
    background-size: 100% auto;
    transform-origin: top;
    transition: transform 3e3ms cubic-bezier(0.19, 1, 0.22, 1),
    opacity 3e3ms cubic-bezier(0.19, 1, 0.22, 1);
    @include res(height,100%,(md:102%));
    @include res(transform,null,(md:scaleY(0)));
    @include res(right,20%);
    @include res(width,2rem);
  }
}
