@import "../../assets/styles/utils";
footer{
  // color: $color-main;
  color: #44797B;
  background: url(../../assets/images/bg_img.jpg) repeat;
  @include res(padding-top,1.1rem,(xs:.4rem));
  @include res(padding-bottom,.1rem);
  @include res(text-align,null,(xs:center));
  a{
    transition: all .3s;
    &:hover{
      color: $color-main-dark;
    }
  }
  .footer{
    align-items: stretch;
    justify-content: space-between;
    @include res(font-size,.2rem,(md:18px,sm:16px,xs:.26rem));
    @include res(display,flex,(xs:block));
    @include res(margin-bottom,.95rem,(xs:.2rem));
    .left{
      flex-shrink: 0;
      @include res(width,3.2rem,(md:300px,sm:260px,xs:100%));
      .logo img{
        display: block;
        height: auto;
        @include res(margin,null,(xs:0 auto));
        @include res(width,3.04rem,(md:240px,sm:200px));
      }
      .info{
        @include res(margin-top,null,(xs:20px));
        .item{
          align-items: flex-start;
          justify-content: flex-start;
          @include res(display,null,(xs:flex));
          @include res(text-align,null,(xs:left));
          @include res(margin-top,.2rem,(md:14px,xs:5px));
          h5{
            font-weight: bold;
            flex-shrink: 0;
            @include res(line-height,null,(xs:1.2));
            @include res(margin-bottom,.05rem);
            &::after{
              content: '：';
              @include res(display,none,(xs:inline));
            }
          }
          p{
            line-height: 1.2;
            br{
              @include res(display,null,(xs:none));
            }
          }
        }
      }
    }
    .nav{
      @include res(display,null,(xs:none));
      h5{
        font-weight: bold;
      }
      ul li{
        @include res(margin-top,.05rem);
      }
    }
    .right{
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      flex-shrink: 0;
      @include res(margin-top,null,(xs:.1rem));
      @include res(min-width,1.8rem,(md:180px,sm:140px,xs:100%));
      @include res(display,flex,(xs:block));
      .item{
        @include res(display,null,(xs:inline-block));
        &.ewm-cont{
          @include res(display,null,(xs:none));
        }
        &.btm-show{
          .ewm{
            .img{
              right: 50%;
              top: 130%;
              transform: translateY(0) translateX(50%);
              &::after{
                border-color: transparent transparent #fff transparent;
                bottom: 100%;
                top: auto;
                left: 50%;
                transform: translateY(0) translateX(-50%);
              }
            }
          }
        }
        &:not(:last-child){
          @include res(margin-right,.4rem);
        }
        h5{
          font-weight: bold;
          line-height: 2;
        }
        .ewm{
          cursor: pointer;
          display: inline-block;
          position: relative;
          @include res(margin-top,.1rem);
          &:hover{
            .img{
              opacity: 1;
              visibility: visible;
            }
          }
          .icon{
            line-height: 1;
            .iconfont{
              color: #ae9f7c;
              @include res(font-size,.44rem,(md:34px));
            }
          }
          .img{
            position: absolute;
            right: 130%;
            top: 50%;
            opacity: 0;
            visibility: hidden;
            transition: all .4s;
            transform: translateY(-50%);
            &::after{
              content: '';
              display: block;
              border: 10px solid;
              border-color: transparent transparent transparent #fff;
              position: absolute;
              left: 100%;
              top: 50%;
              transform: translateY(-50%);
            }
            img{
              display: block;
              height: auto;
              @include res(width,1.3rem,(md:100px));
            }
          }
        }
      }
    }
  }
  .copyright{
    text-align: center;
    @include res(line-height,null,(xs:1.2));
    @include res(font-size, .18rem,(md:16px,sm:14px,xs:.26rem));
  }
}