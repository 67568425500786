@import "../../assets/styles/utils";

.header{
  &.scroll{
    .menu-icon{
      div{
        span,&::before,&::after{
          background-color: #535353 !important;
        }
      }
      &::after{
        opacity: 1;
        transform: scale(1);
      }
    }
    .yuding-btn::after{
      opacity: 1;
      transform: scaleX(1);
    }
  }
  .logo{
    border-bottom: solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    @include res(border-bottom-width,.01rem,(md:1px));
    @include res(height,$header-height-base,$header-height-ratio);
    img{
      display: block;
      width: auto;
      @include res(height,.6rem,(md:.7rem,sm:.8rem,xs:.6rem));
    }
  }
  .menu-icon{
    position: fixed;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include res(top,.55rem,(md:.45rem,xs:.1rem));
    @include res(left,.55rem,(md:.3rem,xs:.2rem));
    @include res(width,.8rem,(md:1rem,xs:.8rem));
    @include res(height,.8rem,(md:1rem,xs:.8rem));
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(242,242,242,.8);
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform-origin: center;
      transform: scale(0);
      transition: all .6s;
      opacity: 0;
    }
    &:hover{
      div{
        span,&::before,&::after{
          width: 100%;
        }
      }
    }
    div{
      position: relative;
      @include res(width,50%);
      @include res(height,.03rem,(md:2px));
      span{
        display: block;
        width: 75%;
        height: 100%;
        transition: all .4s;
        background-color: #535353;
      }
      &::before,&::after{
        content: '';
        display: block;
        height: 100%;
        background-color: #535353;
        position: absolute;
        left: 0;
        transition: all .4s;
      }
      &::before{
        @include res(width,100%);
        @include res(bottom,400%);
      }
      &::after{
        @include res(width,50%);
        @include res(top,400%);
      }
    }
  }
  .yuding-btn{
    position: fixed;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $color-main-dark;
    transition: color .4s;
    @include res(top,.65rem,(md:.55rem,xs:.2rem));
    @include res(padding-left,.3rem,(md:.4rem,xs:.2rem));
    @include res(padding-right,.3rem,(md:.4rem,xs:.2rem));
    @include res(right,1.3rem,(md:.3rem,xs:.2rem));
    @include res(height,.6rem,(md:.8rem,xs:.6rem));
    @include res(font-size,.2rem,(md:18px,sm:16px,xs:14px));
    &:hover{
      color: $color-main;
      .img{
        opacity: 1;
        visibility: visible;
      }
    }
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(242,242,242,.8);
      border-radius: 50px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform-origin: center;
      transform: scaleX(.3);
      transition: all .6s;
      opacity: 0;
    }
    .img{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transition: all .3s;
      opacity: 0;
      visibility: hidden;
      @include res(top,calc(100% + .1rem));
      &::after{
        content: '';
        display: block;
        border: .1rem solid;
        border-color: transparent transparent #fff transparent;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
      img{
        display: block;
        height: auto;
        @include res(width,2.3rem,(xs:1.7rem));
      }
    }
  }
}

.hidden-header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 101;
  box-sizing: border-box;
  pointer-events: none;
  overflow: hidden;
  transition: all 2.3s $anime-bezier;
  @include res(padding-left,.2rem);
  @include res(padding-right,.2rem);
  &.active{
    height: 100vh;
    pointer-events: all;
    .bg-img b{
      transform: scale(1);
    }
    .cont{
      opacity: 1;
      transition-delay: .3s;
      .close-btn,.yuding-btn .t{
        opacity: 1;
        transition-delay: .8s;
      }
      .left{
        .logo{
          opacity: 1;
          transform: translateY(0);
          transition-delay: .7s;
          @include res(transition-delay,.7s,(xs:.3s));
        }
        .info{
          .item{
            &:nth-child(1){
              h3,p{@include res(transition-delay,.9s,(xs:1s));}
            }
            &:nth-child(2){
              h3,p{@include res(transition-delay,1s,(xs:1.1s));}
            }
            h3,p{
              opacity: 1;
              transform: translateY(0);
              
            }
          }
        }
      }
      .nav{
        ul li{
          .nav-title span{
            @include res(transform,translateY(0));
          }
          &:nth-child(1) .nav-title span{
            @include res(transition-delay,.7s,(xs:.4s));
          }
          &:nth-child(2) .nav-title span{
            @include res(transition-delay,.8s,(xs:.5s));
          }
          &:nth-child(3) .nav-title span{
            @include res(transition-delay,.9s,(xs:.6s));
          }
          &:nth-child(4) .nav-title span{
            @include res(transition-delay,1s,(xs:.7s));
          }
          &:nth-child(5) .nav-title span{
            @include res(transition-delay,1.1s,(xs:.8s));
          }
          &:nth-child(6) .nav-title span{
            @include res(transition-delay,1.2s,(xs:.9s));
          }
        }
        .right-link{
          a{
            opacity: 1;
            transform: translateY(0);
            &:nth-child(1){
              @include res(transition-delay,1s,(xs:1s));
            }
            &:nth-child(2){
              @include res(transition-delay,1.2s,(xs:1.1s));
            }
          }
        }
      }
    }
  }
  .bg-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    overflow: hidden;
    height: 100vh;
    b{
      display: block;
      width: 102%;
      height: 102vh;
      background-position: center;
      background-size: cover;
      position: absolute;
      top: -1vh;
      left: -1%;
      filter: blur(3px);
      transform: scale(1.05);
      transition: all 2.3s $anime-bezier;
    }
  }
  .cont{
    background: url(./images/header_bg1.png) repeat;
    position: relative;
    overflow: hidden;
    transition: opacity 2.3s $anime-bezier;
    @include res(margin-top,.2rem);
    @include res(height,calc(100vh - .2rem));
    @include res(opacity,0);
    @include res(border-top-left-radius,.4rem);
    @include res(border-top-right-radius,.4rem);
    @include res(padding-left,10%, 5 / 10);
    @include res(padding-right,10%, 5 / 10);
    @include res(padding-top,5%,(xs:2rem));
    @include res(padding-bottom,0,(xs:5%));
    .close-btn{
      position: absolute;
      cursor: pointer;
      transition: all .3s;
      @include res(opacity,0);
      @include res(top,.45rem,(xs:.3rem));
      @include res(left,.7rem,(xs:.3rem));
      &:hover{
        .iconfont{
          transform: rotate(90deg);
        }
      }
      .iconfont{
        line-height: 1;
        transition: all .3s;
        display: inline-block;
        @include res(font-size,.46rem);
      }
    }
    .yuding-btn{
      position: absolute;
      color: $color-main-dark;
      cursor: pointer;
      transition: all .3s;
      @include res(top,.6rem,(xs:.3rem));
      @include res(right,.7rem,(xs:.3rem));
      @include res(font-size,.2rem);
      &:hover{
        .t span{
          color: $color-main;
        }
        .img{
          opacity: 1;
          visibility: visible;
        }
      }
      .t{
        transition: all .3s;
        @include res(opacity,0);
        span{
          transition: all .3s;
        }
      }
      .img{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        transition: all .3s;
        opacity: 0;
        visibility: hidden;
        @include res(top,calc(100% + .1rem));
        &::after{
          content: '';
          display: block;
          border: .1rem solid;
          border-color: transparent transparent #fff transparent;
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
        }
        img{
          display: block;
          height: auto;
          @include res(width,2rem,(xs:1.3rem));
        }
      }
    }
    .flex{
      width: 100%;
      height: 100%;
      @include res(text-align,null,(xs:center));
      @include res(overflow-y,hidden,(xs:auto));
      @include res(display,flex,(xs:block));
      @include res(flex-direction,row-reverse);
      @include res(align-items,center);
      @include res(justify-content,flex-end);
    }
    .left{
      flex-shrink: 0;
      @include res(margin-right,2.15rem,(md:3rem,sm:2rem,xs:0));
      .logo{
        transition: all 1s $anime-bezier;
        @include res(transform,translateY(.2rem));
        @include res(opacity,0);
        @include res(top,null,(xs:10px));
        @include res(left,null,(xs: calc(50% - .75rem)));
        @include res(position,static,(xs:absolute));
        @include res(margin-bottom,1.5rem);
        img{
          display: block;
          height: auto;
          @include res(width,2.69rem,(xs:1.5rem));
        }
      }
      .info{
        line-height: 1.2;
        .item{
          @include res(margin-top,.7rem,(xs:.3rem));
          h3{
            color: $color-main-dark;
            transition: all 1s $anime-bezier;
            @include res(transform,translateY(.2rem));
            @include res(opacity,0);
            @include res(font-size,.18rem,(md:16px,xs:14px));
            @include res(margin-bottom,.1rem);
          }
          p{
            transition: all 1s $anime-bezier;
            @include res(transform,translateY(.2rem));
            @include res(opacity,0);
            @include res(max-width,2.5rem,(md:4.2rem,sm:5.8rem,xs:100%));
            @include res(font-size,.2rem,(md:18px,xs:16px));
            a{
              color: $color-main;
              transition: all .3s;
              &:hover{
                color: $color-main-dark;
              }
            }
            &.tel{
              line-height: 1;
              @include res(font-size,.32rem,(md:24px,xs:20px));
            }
          }
        }
      }
    }
    .nav{
      flex-shrink: 0;
      @include res(margin-bottom,0,(xs:.6rem));
      ul{
        @include res(width,5.3rem,(md:auto));
        li{
          position: relative;
          @include res(margin-top,.45rem,(xs:.2rem));
          &:hover{
            .btm-line{
              &::before,&::after{
                width: 100%;
              }
              b{
                opacity: 1;
                transition-delay: .4s;
                transform: translateY(-35%) translateX(0);
              }
            }
            .nav-img{
              opacity: 1;
              transition-delay: .4s;
              >div b{
                transform: translateY(0);
                transition-delay: .4s;
              }
            }
          }
          &:last-child{
            .nav-img{
              top: auto;
              bottom: 0;
              transform: translateY(25%);
            }
          }
          .nav-title{
            overflow: hidden;
            color: $color-main;
            @include res(font-size,.2rem,(md:18px,sm:16px));
            span{
              display: inline-block;
              transition: 1.3s $anime-bezier;
              @include res(transform,translateY(100%));
            }
          }
          .btm-line{
            position: relative;
            z-index: 1;
            pointer-events: none;
            @include res(display,block,(md:none));
            @include res(height,.04rem);
            @include res(width,calc(100% - 2rem));
            &::before{
              content: '';
              display: block;
              width: 0;
              background-color: $color-main-dark;
              position: absolute;
              left: 0;
              transition: all 1.3s $anime-bezier;
              @include res(top,0);
              @include res(height,.04rem,(md:1px));
            }
            &::after{
              content: '';
              display: block;
              width: 0;
              background-color: $color-main-dark;
              position: absolute;
              left: 0;
              transition: all 1.3s $anime-bezier;
              @include res(top,.12rem);
              @include res(height,.02rem,(md:1px));
            }
            b{
              display: block;
              background: url(../../assets/images/icon_img4.png) no-repeat top center;
              background-size: contain;
              position: absolute;
              left: 99%;
              top: 0%;
              transition: all 1.3s $anime-bezier;
              opacity: 0;
              transform: translateY(-35%) translateX(-0.25vw);
              @include res(width,.69rem);
              @include res(height,.6rem);
            }
          }
          .nav-img{
            position: absolute;
            border: solid $color-main-dark;
            right: 0;
            top: 50%;
            opacity: 0;
            transition: .8s $anime-bezier;
            transform: translateY(-45%);
            pointer-events: none;
            @include res(display,block,(md:none));
            @include res(border-radius,100px);
            @include res(border-width,.01rem,(md:1px));
            @include res(padding,.05rem);
            @include res(width,1.7rem);
            @include res(height,2.6rem);
            >div{
              width: 100%;
              height: 100%;
              overflow: hidden;
              position: relative;
              @include res(border-radius,100px);
              b{
                position: absolute;
                width: 100%;
                height: 110%;
                top: -5%;
                left: 0%;
                background-position: center;
                background-size: cover;
                transition: 1.5s $anime-bezier;
                transform: translateY(10px);
              }
            }
          }
        }
      }
      .right-link{
        @include res(position,absolute,(xs:static));
        @include res(bottom,1rem);
        @include res(right,.9rem);
        a{
          display: block;
          transition: opacity 1.3s $anime-bezier,transform 1.3s $anime-bezier,color .3s;
          color: $color-main;
          @include res(transform,translateY(.2rem));
          @include res(opacity,0);
          @include res(margin-top,.05rem,(xs:.2rem));
          @include res(font-size,.18rem,(md:16px,xs:16px));
          span{
            transition: all .3s;
            &:hover{
              color: $color-main-dark;
            }
          }
        }
      }
    }
  }
}