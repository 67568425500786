@import "../../assets/styles/utils";

.common-btm-link{
  @include res(padding-left,1.1rem,(xs:.2rem));
  @include res(padding-right,1.1rem,(xs:.2rem));
  @include res(padding-bottom,1.5rem,(xs:.6rem));
  .swiper{
    position: relative;
    overflow: hidden;
    .swiper-slide{
      a{
        display: block;
        position: relative;
        overflow: hidden;
        border: solid #e6d7b8;
        box-sizing: border-box;
        @include res(height,100vh,(md:10rem));
        @include res(border-width,.4rem,(xs:.3rem));
        &:hover{
          .btn span{
            background-color: #e6d7b8;
            color: #333;
          }
        }
        &::after{
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,.2);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
        .img{
          width: 100%;
          height: 120%;
          position: absolute;
          top: -10%;
          left: 0;
          z-index: 0;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
        .text,.btn{
          position: absolute;
          z-index: 2;
          text-align: center;
          width: 100%;
          left: 0;
        }
        .text{
          @include res(top,50%,(xs:60%));
          h2{
            line-height: 1.4;
            @include res(font-size,.7rem);
          }
          .desc{
            color: #fff;
            @include res(font-weight,lighter,(md:normal));
            @include res(font-size,.28rem);
          }
        }
        .btn{
          @include res(bottom,.4rem);
          span{
            display: inline-block;
            border-style: solid;
            border-color: #e6d7b8;
            border-radius: 50px;
            @include res(font-weight,lighter,(md:normal));
            transition: all .3s;
            @include res(color,#e6d7b8,(md:#333));
            @include res(background-color,transparent,(md:#e6d7b8));
            @include res(font-size,.18rem,(md:.26rem,sm:.34rem,xs:.3rem));
            @include res(border-width,.01rem,(md:1px));
            @include res(padding-left,.5rem);
            @include res(padding-right,.5rem);
            @include res(padding-top,.06rem);
            @include res(padding-bottom,.06rem);
          }
        }
      }
    }
    .swiper-button-next,.swiper-button-prev{
      margin-top: 0;
      @include res(top,52%,(xs:61%));
      @include res(width,.25rem);
      @include res(height,.68rem);
      &.swiper-button-disabled{
        pointer-events: all;
      }
    }
    .swiper-button-next{
      background-image: url(../../assets/images/btn_right_color.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      @include res(right,.7rem,(xs:.5rem));
    }
    .swiper-button-prev{
      background-image: url(../../assets/images/btn_left_color.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      @include res(left,.7rem,(xs:.5rem));
    }
    .center{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      text-align: center;
      background-color: $color-main;
      @include res(bottom,.8rem,(xs:48%));
      @include res(width,3.4rem);
      @include res(padding-left,.6rem,(md:.3rem));
      @include res(padding-right,.6rem,(md:.3rem));
      @include res(padding-top,1.25rem,(md:.8rem,xs:.3rem));
      @include res(padding-bottom,.6rem,(md:.4rem,xs:.2rem));
      &::after{
        content: '';
        display: block;
        pointer-events: none;
        position: absolute;
        box-sizing: border-box;
        border: solid #899e97;
        @include res(border-width,.01rem,(md:1px));
        @include res(top,.1rem);
        @include res(left,.1rem);
        @include res(width,calc(100% - .2rem));
        @include res(height,calc(100% - .2rem));
      }
      .logo{
        img{
          height: auto;
          @include res(width,71%);
        }
      }
      .text{
        color: #f1ebda;
        line-height: 1.5;
        @include res(font-size,null,(xs:.26rem));
        @include res(margin-top,.4rem,(md:.1rem));
        @include res(margin-bottom,.7rem,(md:.4rem,xs:.1rem));
      }
      .icon{
        img{
          height: auto;
          @include res(width,.68rem);
        }
      }
    }
  }
}