@font-face {
  font-family: "iconfont"; /* Project id 4224342 */
  src: url('iconfont.woff2?t=1700722129661') format('woff2'),
       url('iconfont.woff?t=1700722129661') format('woff'),
       url('iconfont.ttf?t=1700722129661') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-xiaohongshu:before {
  content: "\e601";
}

.icon-weixin:before {
  content: "\e62d";
}

.icon-triangle-play:before {
  content: "\e617";
}

.icon-gongsiyoubian:before {
  content: "\e60b";
}

.icon-gongsidizhi:before {
  content: "\e60c";
}

.icon-gongsidianhua:before {
  content: "\e60d";
}

.icon-sanjiaoxia:before {
  content: "\e644";
}

.icon-bofang:before {
  content: "\e87c";
}

.icon-guanbi:before {
  content: "\eb6a";
}

.icon-ico:before {
  content: "\e646";
}

