@import "../../assets/styles/utils";

.common-video-swiper{
  position: relative;
  @include res(margin-top,1.3rem,(xs:.8rem));
  @include res(margin-bottom,2.2rem,(xs:1.4rem));
  .swiper{
    overflow: hidden;
    @include res(height,calc(50vw - .8rem),(xs:58vw));
    .swiper-slide{
      position: relative;
      cursor: pointer;
      .img{
        width: 100%;
        height: 120%;
        background-position: center;
        background-size: cover;
        position: absolute;
        left: 0;
        top: -8%;
      }
      .icon{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        @include res(height,1.3rem);
        @include res(width,1.3rem);
        &:hover{
          &::after{
            opacity: 1;
            transform: scale(1);
          }
        }
        .iconfont{
          line-height: 1;
          @include res(font-size,.7rem);
        }
        &::after{
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: solid rgba(255,255,255,.3);
          position: absolute;
          top: 0%;
          left: 0%;
          box-sizing: border-box;
          transform-origin: center;
          transition: all .5s;
          transform: scale(0);
          opacity: 0;
          @include res(border-width,.05rem);
        }
      }
    }
  }
  .swiper-contral{
    position: absolute;
    z-index: 2;
    transform: translate3d(-50%,50%,0);
    bottom: 0;
    left: 50%;
    .common-tag .tag{
      display: block;
      padding: 0.54rem 0.7rem;
      text-align: center;
      @include res(width,6.1rem,(xs:5rem));
      @include res(font-size,.4rem,(xs:.36rem));
    }
    .swiper-button-next,.swiper-button-prev{
      background-image: none;
      width: auto;
      height: auto;
      margin: 0;
      top: 50%;
      transition: all .3s;
      transform: translateY(-50%);
      @include res(padding,.2rem 0);
      &.swiper-button-lock{
        display: block;
      }
      &.swiper-button-disabled{
        opacity: 1;
      }
      &::after{
        content: '';
        display: block;
        background-color: #fff;;
        @include res(height,.01rem,(md:1px));
        @include res(width,.65rem);
      }
      img{
        display: block;
        position: absolute;
        top: 50%;
        height: auto;
        transform: translateY(-50%);
        transition: all .3s;
        @include res(width,.18rem);
      }
    }
    .swiper-button-next{
      @include res(right,.3rem);
      img{
        right: 0;
      }
      &:hover img{
        @include res(right,-.05rem);
      }
    }
    .swiper-button-prev{
      @include res(left,.3rem);
      img{
        left: 0;
      }
      &:hover img{
        @include res(left,-.05rem);
      }
    }
  }
}