@import "../../assets/styles/utils";

.ny-banner{
  position: relative;
  overflow: hidden;
  @include res(min-height,6rem);
  @include res(height,100vh);
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .bg-img{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    &.is-inview b{
      transform: scale(1);
    }
    img{
      display: block;
      width: 100%;
      height: auto;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
    }
    b{
      display: block;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      transform: scale(1.05);
    }
  }
  .logo{
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    @include res(top,.6rem,(xs:.2rem));
    img{
      display: block;
      width: auto;
      @include res(height,.6rem,(md:.7rem,sm:.8rem,xs:.6rem));
    }
  }
  .btm-text{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    text-align: center;
    @include res(padding-bottom,.6rem);
    @include res(padding-left,6.5%, 5 / 6.5);
    @include res(padding-right,6.5%, 5 / 6.5);
    .flourish{
      line-height: 1.2;
      @include res(font-size,1.6rem,(xs:.8rem));
    }
    .t1{
      color: #efe3c6;
      @include res(font-weight,lighter,(md:normal));
      @include res(font-size,.28rem,(xs:.3rem));
      @include res(margin-bottom,.3rem);
    }
    .icon{
      @include res(margin-top,.3rem);
      img{
        width: auto;
        @include res(height,.25rem);
      }
    }
  }
}